import React, { useState, useEffect } from 'react'
import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner"

const MultiMediaBox = (props) => {
    let [getImages, setGalleryImages] = useState([]);
    let [page, setPage] = useState(false);
    let [total, setTotal] = useState(1);
    let [boxshow, setBoxShow] = useState('none');
    let [imagesId, setImagesId] = useState([]);
    let [images, setImages] = useState([]);

    const fetchImages = () => {
        setTimeout(() => {
            axios.get(`https://jsonplaceholder.typicode.com/photos?_page=${total}&_limit=16`)
            .then((response) => {
                setGalleryImages([...getImages, ...response.data]);
                setPage(true);
                setTotal(total + 1);

            })
            .catch((error) => {
                console.log(error);
            });
         }, 1500);    
            
    }

    useEffect(() => {
        fetchImages();
        // selected images 
        if(props.image_id && imagesId.length == 0){
            const ids = props.image_id;
            var idsArray = ids.split(',');
              for (const [i, id] of idsArray.entries()) { 
                axios
                    .get(
                      `https://jsonplaceholder.typicode.com/photos/${id}`
                    )
                    .then((response) => {
                        images.push(response.data);
                        imagesId.push(response.data.id);
                    });
              }
        }
       
    }, [props]);
     
    const selectImage = (data) => {
        setImagesId([...imagesId, data.id]);
        setImages([...images, data]);
        setBoxShow('none');
    }
    const removeItem = (itemId) => {
        setImages(prevState => prevState.filter(({ id }) => id !== itemId));
        setImagesId(imagesId.filter((item, index) => item !== itemId));
    }
    const openMultiMediaBox = () => {
        setBoxShow('block');
    }
    const closeMultiMedia = () => {
        setBoxShow('none');
    }
    
    return (
        <>
            <input type="button" value="Select" className="btn btn-outline-primary btn-md d-block shadow-none" onClick={openMultiMediaBox} />
            <input type="hidden" id="galleries" value={imagesId} />
            <div className="row">
                {images &&
                    images.map((image, index) => {
                        return (
                            <div className="col-md-4 p-0 position-relative" key={index} >
                                <button type="button" key={index} className="btn-close img-remove shadow-none" onClick={() => removeItem(image.id)}></button>
                                <img src={image.url} alt="img" className="mt-2 img-thumbnail" />
                            </div>
                        )
                    })
                }
            </div>
            <div className="modal fade show multi MediaBox" style={{ display: boxshow }} >
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">

                        <div className="modal-header ">
                            <h4 className="modal-title">Media</h4>
                            <button type="button" className="btn-close" onClick={closeMultiMedia}></button>
                        </div>

                        <div className="modal-body" id="scrollablemulti">

                            <InfiniteScroll
                                dataLength={getImages.length}
                                next={fetchImages}
                                hasMore={page}
                                loader={
                                    <div className="text-center">
                                        <Loader
                                            type="ThreeDots"
                                            color="#00BFFF"
                                            height={100}
                                            width={100}
                                            timeout={3000}
                                        />
                                    </div>
                                }
                                scrollableTarget="scrollablemulti"
                            >
                                <div className="row">
                                    {getImages.map((data, index) => (
                                        <div key={index} className="col-sm-12 col-md-6 col-lg-3 mb-2 media-box">
                                            <img key={data.albumId} className="img-fluid" alt="img" src={data.thumbnailUrl} />
                                            <div className="overlay py-5 text-center">
                                                <a className="btn btn-info" onClick={() => selectImage(data)} >Select</a>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default MultiMediaBox