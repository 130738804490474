import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Title from '../../components/Title'
import { Token } from "../../components/Firebase";

const Login = () => {
	Title('Login'); 
	// Check already logged in
	const { register, handleSubmit, reset, formState: { errors, } } = useForm();
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [btnText, setBtnText] = useState('Login');
	const [isSubmit, setIssubmit] = useState(false);
	const [login, setLogin]   = useState(false);
	const history = useHistory();
	//sessionStorage.removeItem('isAdminLogin', false);
	const onSubmit = data => {
		setBtnText('login....');
		setIssubmit(true);
		
		axios.post('Users/adminLogin', data)
		.then(response => { 
			let resp = response.data;
			if(resp.status){
				sessionStorage.setItem('isAdminLogin', true);
				sessionStorage.setItem('admin', JSON.stringify(resp.data));
				sessionStorage.setItem('site_title', resp.title);
				setLogin(true);
			}else{
				setError(resp.msg);
			}
			setBtnText('login');
			setIssubmit(false);
		})
		.catch(error => {
			setError(error);
		})
	}

	const inputChange = (e) => {
		setSuccess(false);
		setError(false);
	}
	// FCM TOKEN for firebase login
	// const [isTokenFound, setTokenFound] = useState(false);
	// useEffect(() => {
	// 	let data;
	// 	async function tokenFunc() {
	// 		data = await Token(setTokenFound);
	// 		if (data) {
	// 			//console.log("Token is", data);
	// 			localStorage.setItem('Token', data);
	// 		}
	// 		return data;
	// 	}
	// 	tokenFunc();
	// }, [setTokenFound]);
// END TOKEN
// Check login
if( sessionStorage.getItem('isAdminLogin')){
	history.push('/admin');
}
if(login){
	history.push('/admin');
}
	return (
		<section className="login">
			<div className="container">
				<div className="row">
					<div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto mt-auto mb-auto  form p-4">
						<h1 className="display-6 text-center">Welcome Back!</h1>
						<div className="login-card">
							{
								success &&
								<div className="alert alert-success">Logged in</div>
							}
							{
								error &&
								<div className="alert alert-danger">{error}</div>
							}
							<form action="" onSubmit={handleSubmit(onSubmit)} className="justify-content-center text-left">
								<div className="form-group mb-3">
									<label className="form-label">User name or Email</label>
									<input
										type="text"
										className={errors.userlogin ? "form-control input-error " : "form-control"}
										autocomple="off"
										{...register("userlogin", { required: true })} placeholder="Jane Doe"
										onInput={inputChange}
									/>
								</div>
								<div className="form-group mb-3">
									<label className="form-label">Password</label>
									<input type="Password"
										className={errors.password ? "form-control input-error " : "form-control"}
										{...register("password", { required: true })}
										autocomple="off"
										placeholder="****"
										onInput={inputChange}
									/>
								</div>
								<button type="submit" className="btn btn-warning btn-lg" disabled={isSubmit}>{btnText}</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Login