import React, { useState, useEffect} from 'react'
import { NavLink, useParams } from 'react-router-dom'
import axios from 'axios'
import Loader from "react-loader-spinner"
import Datatable from 'react-data-table-component'
import Title from '../../components/Title'

const Orders = () => {
	Title('Orders');
	const [orders, setOrders] = useState([]);
	const [loading, setLoading] = useState(false);

	const fetchOrders = async () => {
		setLoading(true);
		const res = await axios.get('https://jsonplaceholder.typicode.com/posts');
		setOrders(res.data);
		setLoading(false);
	};
	const DeleteOrder = (id) => {
		alert(id);
	}
	useEffect(() => {
		fetchOrders();
	}, []);

	const columns = [
		{
			name: 'Order',
			selector: row => row.userId,
			sortable: true,
			grow: 1

		},
		{
			name: 'Date',
			selector: row => row.userId,
			sortable: true,
			grow: 0

		},
		{
			name: 'Status',
			selector: row => row.userId,
			sortable: true,
			grow: 0

		},
		{
			name: 'Total',
			selector: row => row.title,
			sortable: true,
			grow: 1
		},
		{
			name: 'Actions',
		    wrap: false,
		    right: false,
		    cell: row => <div>
		    			<button className="btn btn-danger btn-sm me-2" onClick = { () => DeleteOrder(row.id) } >Delete</button>
		    			<NavLink to={'/admin/order/'+row.userId} className="btn btn-info btn-sm me-2">Edit/View</NavLink>
		    			</div>,
		 	right: true,

		},
	];
	
	return (
		<div className="content-wrapper">
			<div className="content-header">
				<div className="container-fluid mb-2 px-0">
					<div className="row">
						<div className="col-sm-6">
							<ol className="breadcrumb">
				              <li className="breadcrumb-item"><NavLink to="/admin/">Home</NavLink></li>
				              <li className="breadcrumb-item active">Orders</li>
				            </ol>
						</div>
						<div className="col-sm-6 text-sm-end d-none">
							<NavLink to="/admin/add-order" className="btn btn-sm btn-primary">Add Order</NavLink>
						</div>
					</div>
				</div>
			</div>
			<hr />
			{ loading &&
				<div className="content-wrapper text-center">
					<Loader
						type="ThreeDots"
						color="#00BFFF"
						height={100}
						width={100}
					/>
				</div>
			}
			<section className="content">
				<div className="container-fluid px-0">
					<div className="row">
						<Datatable
							title="Orders"
							columns={columns}
							data={orders}
							pagination
							highlightOnHover
							noHeader
							className="table"
						/>
					</div>
				</div>
			</section>
		</div>
	)
}

export default Orders