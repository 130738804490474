import React, { useState, useEffect} from 'react'
import Datatable from 'react-data-table-component'
import Loader from "react-loader-spinner"
import axios from 'axios'
import { useAlert } from 'react-alert'
import { NavLink} from 'react-router-dom'

const url = 'https://jsonplaceholder.typicode.com/posts';

const ProductTags = () => {
	const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(false);
	const alert = useAlert()
	
	useEffect(() => { 
		fetchPosts();
	}, []);

	const fetchPosts = async () =>{ 
			setLoading(true);
			const res = await axios.get(url);
			setPosts(res.data);
			setLoading(false);
		};

	const DeleteTag = (id) =>{
		console.log(id);
		fetchPosts();
		alert.success('Deleted')
	}

	const columns = [ 
	  {
	    name: 'Name',
	    selector: row=> row.userId,
	    sortable: true,
	    width:'auto'

	  },
	  {
	    name: 'Desc',
	    selector:  row=> row.title,
	    sortable: true,
	  },
	  {
	    name: 'Count',
	    selector:  row=> row.userId,
	    sortable: true,
	    width:'auto'
	  },
	  {
	    name: 'Actions',
	    wrap: false,
	    right: false,
	    cell: row => <div><button className="btn btn-danger btn-sm me-2" onClick = { () => DeleteTag(row.userId) } >Delete</button><NavLink to={'/admin/tag/'+row.userId} className="btn btn-info btn-sm">Edit</NavLink></div>,
	 	width:'auto'
	  },
	];

	return (
		<>
		{ loading && 
			<div className="text-center">
				<Loader
					type="ThreeDots"
					color="#00BFFF"
				/>
			</div>
		}
		{! loading && 
				<Datatable
					title="Posts"
					columns={columns}
					data={posts}
					pagination
					highlightOnHover
					noHeader
					className = "table"
				/>	
		}		
		</>
	)
}
export const GetProductTags = (props) => {
	const [tags, setTags] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => { 
		setLoading(true);
		axios.get(url)
			.then(res => { 
		      setTags(res.data);
		    }); 
		setLoading(false);
	}, []);

	if(loading){
		return (
			<div className="text-center">
				<Loader
					type="ThreeDots"
					color="#00BFFF"
					//timeout={3000} 
				/>
			</div>
		)
	}

	return (
		tags.map((tag, index) => {
			return (
				<div className="form-check" key ={tag.id}>
					<input 
						className="form-check-input" 
						type="checkbox" 
						defaultValue={tag.id} 
						id={"tag"+tag.id}
						{...props.register(`tags.${index}`)} 
					/>
					<label className="form-check-label" htmlFor={"tag"+tag.id}>{tag.title}</label>
				</div>
			)
		})

	)

}
export default ProductTags