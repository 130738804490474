import { useState, useEffect } from "react";
import { getMessaging, onMessage } from "firebase/messaging";

const Header = () => {
	// Logout 
	const logout = () =>{
		//sessionStorage.removeItem('admin');
		//sessionStorage.removeItem('isAdminLogin');
		sessionStorage.clear();
		window.location.href = '/admin/login';
	}
	// Notification firebase
	
	//	localStorage.removeItem('noti-count');
	//localStorage.setItem('noti-count', 0);

	let localCount = localStorage.getItem('noti-count');
	if (localCount) {
		localCount = localStorage.getItem('noti-count');
	} else {
		localCount = 0;
	}

	const [count, setCount] = useState(localCount);
	const [ismsg, setIsmsg] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [newnotifications, setNewNotifications] = useState([]);

	const messaging = getMessaging();

	onMessage(messaging, (payload) => {
		setIsmsg(false);
		//let dataTitle = payload['data']['Title'];
		//let dataMessage = payload['data']['Message'];

		let notiTitle = payload['notification']['title'];
		let notiMessage = payload['notification']['body'];
		setNewNotifications('');
		setNewNotifications({ 'title': notiTitle, 'message': notiMessage })
		setIsmsg(true);
	});

	useEffect(() => {
		if (ismsg) {
			localStorage.setItem('noti-count', parseInt(count) + 1);
			setCount(localStorage.getItem('noti-count'));
			notifications.push(newnotifications);
		}
	}, [ismsg]);
	return (
		<>
			{/* Toasted */}
			<div className="position-fixed bottom-0 end-0 p-3 mytoast">

				{notifications &&
					notifications.map((noti, index) => {
						return (
							<div key={index} className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
								<div className="toast-header">
									<strong className="me-auto">{noti.title}</strong>
									<small>11 mins ago</small>
									<button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
								</div>
								<div className="toast-body bg-gray">
									{noti.message}
								</div>
							</div>
						)
					})
				}
			</div>

			{/* Toasted */}
			<nav className="main-header navbar navbar-expand">
				<ul className="navbar-nav ">
					<li className="nav-item">
						<a className="nav-link" onClick={HideSidebar} href="#!" role="button"><i className="fa fa-bars"></i></a>
					</li>
				</ul>

				<ul className="navbar-nav ms-auto me-2">
					<li className="nav-item dropdown nv">
						<a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							<i className="fa fa-bell"></i>
							<span className="badge badge-warning navbar-badge notification-count">{count}</span>
						</a>
						<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
							<span className="dropdown-item dropdown-header">{count} Notifications</span>
							{notifications &&
								notifications.map((noti, index) => {
									return (
										<div key={index}>
											<div className="dropdown-divider"></div>
											<a href="#!" className="dropdown-item">
												<i className="fa fa-envelope mr-2"></i> {noti.title}
											</a>
										</div>
									)
								})
							}
						</div>
					</li>
					<li className="nav-item dropdown nv">
						<a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							<i className="fa fa-user"></i>
						</a>
						<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" >
							<a onClick={ logout} className="dropdown-item">
								<i className="fa fa-sign-out mr-2"></i> Logout
							</a>

						</div>
					</li>

				</ul>
			</nav>

		</>
	)
}
export default Header

const HideSidebar = (e) => {
	e.preventDefault();

	const div = document.querySelector('.main-sidebar');
	const body = document.querySelector('body');
	if (div.classList.contains('open')) {
		div.classList.add('closed');
		div.classList.remove('open');
	} else {
		div.classList.remove('closed');
		div.classList.add('open');
	}
	if (body.classList.contains('sidebar-collapse')) {
		body.classList.remove('sidebar-collapse');
	} else {
		body.classList.add('sidebar-collapse');
	}
}
