import React, { useState, useEffect } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import './App.css';
import './assets/font-awesome/css/font-awesome.min.css';

import Home  from './views/Home';
import NotFound  from './views/NotFound';
import AdminRoutes from './routes/AdminRoutes'
import AdminLayout from  './layouts/Admin';
import AdminLogin from './views/admin/Login'

function App(props) {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          {/* <Route exact path='/' component={Home} />  */}
          <Route exact path='/' component={AdminLogin} /> 
          <Route exact path='/admin/login' component={AdminLogin} /> 

           {
            AdminRoutes.map((prop, key) => { 
                let path = prop.layout + prop.path; 
                if(prop.isSingle){
                  path = prop.layout + prop.path + ':id';
                }
                return (
                  <Route
                    exact
                    path={path}
                    component= {AdminLayout}
                    key={key}
                  />
                );
         
              })
          }
          <Route exact path='*' component={NotFound} /> 
        </Switch>
      </BrowserRouter>     
    </div>
  );
}

export default App;
