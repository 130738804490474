import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import { NavLink } from 'react-router-dom'
import MediaBox from '../../components/MediaBox'
import { useAlert } from 'react-alert'
import axios from 'axios'
import Title from '../../components/Title'

const Settings = () => {
	Title('Settings');
	const { register: generalRegister, handleSubmit: handleSubmitGeneral, setValue: setGeneral, formState: { errors: errors1 } } = useForm();
	const { register: companyRegister, handleSubmit: handleSubmitCompony, setValue: setCompany, formState: { errors: errors2 } } = useForm();
	const { register: paymentRegister, handleSubmit: handleSubmitPayment, setValue: setPayment, formState: { errors: errors3 } } = useForm();
	const { register: emailRegister, handleSubmit: handleSubmitEmail, setValue: setEmail, formState: { errors: errors4 } } = useForm();
	// Ecom Tabs
	const { register: ecomRegister, handleSubmit: handleSubmitEcom, formState: { errors: errors5 } } = useForm();
	const { register: shippingRegister, handleSubmit: handleSubmitShipping, formState: { errors: errors6 } } = useForm();
	const { register: taxRegister, handleSubmit: handleSubmitTax, formState: { errors: errors7 } } = useForm();
	const { register: couponRegister, handleSubmit: handleSubmitCoupon, formState: { errors: errors8 } } = useForm();

	const [btnText, setBtnText] = useState('Save Settings');
	const [isSubmit, setIssubmit] = useState(false);
	const [image, setImage]   = useState('');
	const alert = useAlert()
	//getSettings
	useEffect(() => {
		const fetchPosts = async () => {
			await axios.get('Admin/getSettings')
			.then(response => { 
				if(response.data.status){ 
					let data = response.data.data;
					// General 
					setGeneral('site_title', data.site_title);
					setGeneral('business_email', data.business_email);
					setGeneral('business_phone', data.business_phone);
					setGeneral('copyright', data.copyright);
					setGeneral('post_per_page', data.post_per_page);
					setImage(data.logo);
					//Company
					setCompany('company_name', data.company_name);
					setCompany('address', data.address);
					setCompany('city', data.city);
					setCompany('state', data.state);
					setCompany('country', data.country);
					setCompany('zipcode', data.zipcode);
					// SMTP email
					setEmail('smtp_host', data.smtp_host);
					setEmail('smtp_port', data.smtp_port);
					setEmail('smtp_email', data.smtp_email);
					setEmail('smtp_username', data.smtp_username);
					setEmail('smtp_password', data.smtp_password);
					// Payment
					setPayment('paypal_active', data.paypal_active);
					setPayment('paypal_test', data.paypal_test);
					setPayment('paypal_clientid', data.paypal_clientid);
					setPayment('paypal_secret', data.paypal_secret);
					setPayment('stripe_active', data.stripe_active);
					setPayment('stripe_test', data.stripe_test);
					setPayment('stripe_key', data.stripe_key);
					setPayment('stripe_secret', data.stripe_secret);
					setPayment('cash_on_delivery', data.cash_on_delivery);
				}
			})
			.catch(error => {
			  alert.error(error);
			})
		};
		fetchPosts();
	}, []);
	// General Tab sub
	const generalSubmit = data => {
		setBtnText('Saving...');
		setIssubmit(true);
		const image_id = document.querySelector('#image_id').value;
		data.logo  = image_id;
		
		axios.post('Admin/updateSettings/general', data)
		.then(response => { 
			let resp = response.data;
			alert.success(resp.msg); 
			setIssubmit(false);
			// Update title
			sessionStorage.setItem('site_title', data.site_title);
			setBtnText('Save Settings');
		})
		.catch(error => {
		  alert.error(error);
		});
	}

	// Company tab
	const companySubmit = data => {
		setBtnText('Updateing...');
		setIssubmit(true);
		axios.post('Admin/updateSettings/general', data)
		.then(response => { 
			let resp = response.data;
			alert.success(resp.msg); 
			setIssubmit(false);
			setBtnText('Save Settings');
		})
		.catch(error => {
		  alert.error(error);
		});
	}
	// Email
	const emailSubmit = data => {
		setBtnText('Updateing...');
		setIssubmit(true);
		console.log(JSON.stringify(data));
		
		axios.post('Admin/updateSettings/general', data)
		.then(response => { 
			let resp = response.data;
			alert.success(resp.msg); 
			setIssubmit(false);
			setBtnText('Save Settings');
		})
		.catch(error => {
		  alert.error(error);
		});
	}
	// Payment Method
	const paymentMethodSubmit = data => {
		setBtnText('Updateing...');
		setIssubmit(true);
		axios.post('Admin/updateSettings/general', data)
		.then(response => { 
			let resp = response.data;
			alert.success(resp.msg); 
			setIssubmit(false);
			setBtnText('Save Settings');
		})
		.catch(error => {
		  alert.error(error);
		});
	}
	// Ecom tabs
	const ecomGeneralSubmit = data => {
		setBtnText('Updateing...');
		setIssubmit(true);
		console.log(JSON.stringify(data));
		
		alert.success('success');
		setIssubmit(false);
		setBtnText('Save Settings');
	}
	const ecomShippingSubmit = data => {
		setBtnText('Updateing...');
		setIssubmit(true);
		console.log(JSON.stringify(data));
		
		alert.success('success');
		setIssubmit(false);
		setBtnText('Save Settings');
	}
	const ecomTaxSubmit = data => {
		setBtnText('Updateing...');
		setIssubmit(true);
		console.log(JSON.stringify(data));
		
		alert.success('success');
		setIssubmit(false);
		setBtnText('Save Settings');
	}
	const ecomCouponSubmit = data => {
		setBtnText('Updateing...');
		setIssubmit(true);
		console.log(JSON.stringify(data));
		
		alert.success('success');
		setIssubmit(false);
		setBtnText('Save Settings');
	}

	return (
		<div className="content-wrapper">
			<div className="content-header">
				<div className="container-fluid mb-2">
					<div className="row">
						<div className="col-sm-6">
							<ol className="breadcrumb">
				              <li className="breadcrumb-item"><NavLink to="/admin/">Home</NavLink></li>
				              <li className="breadcrumb-item active">Settings</li>
				            </ol>
						</div>
						<div className="col-sm-6">
						</div>
					</div>
				</div>
			</div>
			<hr />
			<section className="content">
				<div className="container-fluid p-0 mb-3">
					<div className="row">
						<div className="col-md-3 mb-3">
							<div className="nav nav-tabs settings bg-white card-border-top" id="nav-tab" role="tablist">
			                    <button className="nav-link active" id="nav-general-tab" data-bs-toggle="tab" data-bs-target="#nav-general" role="tab">General</button>
			                    <button className="nav-link" id="nav-company-tab" data-bs-toggle="tab" data-bs-target="#nav-company" role="tab">Company/Store Information</button>
			                    <button className="nav-link" id="nav-payment-tab" data-bs-toggle="tab" data-bs-target="#nav-payment"  role="tab">Payment Gateways</button>
			                  	<button className="nav-link" id="nav-ecom-tab" data-bs-toggle="tab" data-bs-target="#nav-ecom"  role="tab">Ecom</button>
			                    <button className="nav-link end" id="nav-email-tab" data-bs-toggle="tab" data-bs-target="#nav-email"  role="tab">Email</button>
			                </div>
						</div>
						<div className="col-md-9">
							<div className="bg-white">
							{/* General */}
								<div className="tab-pane fade show p-3 active mb-5 card-border-top" id="nav-general" role="tabpanel" aria-labelledby="nav-general-tab">
									<form onSubmit = {handleSubmitGeneral(generalSubmit)}>
										<div className="form-group mb-3">
											<label className="form-label">Logo</label>
												<MediaBox image_id={image} />
										</div>
										<hr />
										<div className="form-group mb-3">
											<label className="form-label">Web Title</label>
											<input type="text" 
												className={ errors1.site_title ? "form-control input-error " : "form-control" }
												autocomple="off" 
				                                {...generalRegister("site_title", { required: true })} 
				                                placeholder= "Web Titlte"
											 />
										</div>
										<div className="form-group mb-3">
											<label className="form-label">Business Email</label>
											<input type="text" 
												className={ errors1.business_email ? "form-control input-error " : "form-control" }
												autocomple="off" 
				                                {...generalRegister("business_email", { required: true, pattern: /^\S+@\S+$/i })} 
				                                placeholder= "Business Email"
											 />
										</div>
										<div className="form-group mb-3">
											<label className="form-label">Business Phone</label>
											<input type="tel" 
												className={ errors1.business_phone ? "form-control input-error " : "form-control" }
												autocomple="off" 
				                                {...generalRegister("business_phone", { required: true, minLength: 10, maxLength: 12 })} 
				                                placeholder= "Bussiness Phone"
											 />
											{errors1.business_phone && <span className="text-danger">Min 10 and max 12 digits</span>}
										</div>
										<div className="form-group mb-3">
											<label className="form-label">Post/page</label>
											<input type="text" 
												className={ errors1.post_per_page ? "form-control input-error " : "form-control" }
												autocomple="off" 
				                                {...generalRegister("post_per_page", { required: true })} 
				                                placeholder= "post per page"
											 />
										</div>
										<div className="form-group mb-3">
											<label className="form-label">Copyright</label>
											<input type="text" 
												className={ errors1.copyright ? "form-control input-error " : "form-control" }
												autocomple="off" 
				                                {...generalRegister("copyright", { required: true })} 
				                                placeholder= "copyright"
											 />
										</div>
										<div className="fixed-bottom-button">
											<button className="btn btn-info btn-md text-white text-uppercase" disabled={ isSubmit  }>{btnText}</button>
										</div>
									</form>
								</div>
								{/* Company info*/}
								<div className="tab-pane fade p-3 mb-5 card-border-top" id="nav-company" role="tabpanel" aria-labelledby="nav-company-tab">
									<form onSubmit = {handleSubmitCompony(companySubmit)}>
										<div className="form-group mb-3">
											<label className="form-label">Company Name</label>
											<input type="text" 
												className={ errors2.company_name ? "form-control input-error " : "form-control" }
												autocomple="off" 
				                                {...companyRegister("company_name", { required: true })} 
				                                placeholder= "Company Name"
											 />
										</div>
										<div className="form-group mb-3">
											<label className="form-label">Address</label>
											<input type="text" 
												className={ errors2.address ? "form-control input-error " : "form-control" }
												autocomple="off" 
				                                {...companyRegister("address", { required: true })} 
				                                placeholder= "Address"
											 />
										</div>
										<div className="form-group mb-3">
											<label className="form-label">City</label>
											<input type="text" 
												className={ errors2.city ? "form-control input-error " : "form-control" }
												autocomple="off" 
				                                {...companyRegister("city", { required: true })} 
				                                placeholder= "City"
											 />
										</div>
										<div className="form-group mb-3">
											<label className="form-label">State</label>
											<input type="text" 
												className={ errors2.state ? "form-control input-error " : "form-control" }
												autocomple="off" 
				                                {...companyRegister("state", { required: true })} 
				                                placeholder= "state"
											 />
										</div>
										<div className="form-group mb-3">
											<label className="form-label">Country</label>
											<input type="text" 
												className={ errors2.country ? "form-control input-error " : "form-control" }
												autocomple="off" 
				                                {...companyRegister("country", { required: true })} 
				                                placeholder= "country"
											 />
										</div>
										<div className="form-group mb-3">
											<label className="form-label">Zip Code</label>
											<input type="text" 
												className={ errors2.zipcode ? "form-control input-error " : "form-control" }
												autocomple="off" 
				                                {...companyRegister("zipcode", { required: true })} 
				                                placeholder= "Zip Code"
											 />
										</div>
										<div className="fixed-bottom-button">
											<button className="btn btn-info btn-md text-white text-uppercase" disabled={ isSubmit  }>{btnText}</button>
										</div>
									</form>
								</div>
							{ /** Payment tab **/ }
								<div className="tab-pane fade p-3 mb-5 card-border-top" id="nav-payment" role="tabpanel" aria-labelledby="nav-payment-tab">
									<form onSubmit = {handleSubmitPayment(paymentMethodSubmit)}>
										<div className="row">
				                            <div className="col-md-12">
				                                <div className="cards">
				                                    <div className="card-body">
				                                        <h5 className="card-title">Paypal</h5>
				                                        <hr />
				                                        <div className="row">
					                                        <div className="col-md-6">
						                                        <div className="form-check form-switch mb-3">
						                                            <input className="form-check-input" 
						                                            	type="checkbox" 
						                                            	defaultValue="1"
						                                             	{...paymentRegister("paypal_active", { required: false })}  
						                                             />
						                                            <label className="form-check-label" htmlFor="paypal">Active</label>
						                                        </div>
					                                        </div>
					                                        <div className="col-md-6">
						                                        <div className="form-check form-switch mb-3">
						                                            <input className="form-check-input" 
						                                            	type="checkbox"
						                                            	defaultValue="1" 
						                                            	{...paymentRegister("paypal_test", { required: false })}  
						                                             />
						                                            <label className="form-check-label" htmlFor="paypal">Test Mode</label>
						                                        </div>
					                                        </div>
				                                        </div>
				                                        <div className="mb-3">
				                                            <label className="form-label">Client ID</label>
				                                            <input type="text" className="form-control" autocomple="off" 
				                                            	{...paymentRegister("paypal_clientid", { required: false })} 
				                                            />
				                                        </div>
				                                        <div className="mb-3">
				                                            <label className="form-label">Secret</label>
				                                            <input type="text" className="form-control" autocomple="off" 
				                                            	{...paymentRegister("paypal_secret", { required: false })} 
				                                            />
				                                        </div>
				                                    </div>
				                                </div>
				                            </div>
				                            <div className="col-md-12">
				                                <div className="cards">
				                                    <div className="card-body">
				                                        <h5 className="card-title">Stripe</h5>
				                                        <hr />
				                                         <div className="row">
					                                        <div className="col-md-6">
						                                        <div className="form-check form-switch mb-3">
						                                            <input className="form-check-input" type="checkbox" 
						                                            	defaultValue="1"
						                                            	{...paymentRegister("stripe_active", { required: false })} 
						                                            />
						                                            <label className="form-check-label" htmlFor="paypal">Active</label>
						                                        </div>
					                                        </div>
					                                        <div className="col-md-6">
						                                        <div className="form-check form-switch mb-3">
						                                            <input className="form-check-input" type="checkbox" 
						                                            	defaultValue="1"
						                                            	{...paymentRegister("stripe_test", { required: false })} 
						                                            />
						                                            <label className="form-check-label" htmlFor="paypal">Test Mode</label>
						                                        </div>
					                                        </div>
				                                        </div>
				                                        <div className="mb-3">
				                                            <label className="form-label">API Key</label>
				                                            <input type="text" className="form-control" autocomple="off" 
				                                            	{...paymentRegister("stripe_key", { required: false })} 
				                                            />
				                                        </div>
				                                        <div className="mb-3">
				                                            <label className="form-label">API Secret</label>
				                                            <input type="text" className="form-control" autocomple="off" 
				                                            	{...paymentRegister("stripe_secret", { required: false })} 
				                                            />
				                                        </div>
				                                    </div>
				                                </div>
				                            </div>
				                            <div className="col-md-12 mt-3">
				                                <div className="cards">
				                                    <div className="card-body">
				                                        <h5 className="card-title">Cash on delivery</h5>
				                                        <hr />
				                                        <div className="form-check form-switch mb-3">
				                                            <input className="form-check-input" type="checkbox"
				                                            	defaultValue="1" 
				                                            	{...paymentRegister("cash_on_delivery", { required: false })} 
				                                            />
				                                            <label className="form-check-label" htmlFor="cod">Active</label>
				                                        </div>
				                                    </div>
				                                </div>
				                            </div>
				                        </div>
										<div className="fixed-bottom-button">
											<button className="btn btn-info btn-md text-white text-uppercase" disabled={ isSubmit  }>{btnText}</button>
										</div>
									</form>
								</div>
							{ /**  Ecom **/}
							<div className="tab-pane fade p-3 mb-5 card-border-top" id="nav-ecom" role="tabpanel" aria-labelledby="nav-ecom-tab">
								
									 <nav>
						                <div className="nav nav-tabs ecom" id="nav-tab" role="tablist">
						                    <button className="nav-link active" id="nav-ecomgeneral-tab" data-bs-toggle="tab" data-bs-target="#nav-ecomgeneral" role="tab">General</button>
						                    <button className="nav-link" id="nav-shipping-tab" data-bs-toggle="tab" data-bs-target="#nav-shipping" role="tab">Shipping</button>
						                    <button className="nav-link" id="nav-tax-tab" data-bs-toggle="tab" data-bs-target="#nav-tax" role="tab">Tax</button>
						                    <button className="nav-link" id="nav-coupon-tab" data-bs-toggle="tab" data-bs-target="#nav-coupon" role="tab">Coupon</button>
						                </div>
						            </nav>
						        { /** ECOM GENERAL **/}
						            <div className="tab-pane fade show pt-3 active" id="nav-ecomgeneral" role="tabpanel" aria-labelledby="nav-ecomgeneral-tab">
							            <form onSubmit = {handleSubmitEcom(ecomGeneralSubmit)}>	
										  <h5 className="card-title">Currency and General options</h5>
	                                        <small>The following options affect how prices are displayed on the frontend.</small>
	                                        <hr />
	                                        <div className="mb-3">
	                                            <label className="form-label"><b>Enable taxes</b></label>
	                                            <div className="form-check">
	                                                <input className="form-check-input" type="checkbox" value="" id="tax" />
	                                                <label className="form-check-label" htmlFor="tax">
	                                                    Enable tax rates and calculations
	                                                </label>
	                                            </div>                                      
	                                        </div>
	                                        <div className="mb-3">
	                                            <label className="form-label"><b>Enable coupons</b></label>
	                                            <div className="form-check">
	                                                <input className="form-check-input" type="checkbox" value="" id="coupon" />
	                                                <label className="form-check-label" htmlFor="coupon">
	                                                    Enable the use of coupon codes
	                                                </label>
	                                            </div> 
	                                        </div>
	                                        <div className="mb-3">
	                                            <label className="form-label"><b>Enable reviews</b></label>
	                                            <div className="form-check">
	                                                <input className="form-check-input" type="checkbox" value="" id="reviews" />
	                                                <label className="form-check-label" htmlFor="reviews">
	                                                    Enable product reviews
	                                                </label>
	                                            </div>                                      
	                                        </div>
	                                        <div className="mb-3">
	                                            <label className="form-label"><b>Product ratings</b></label>
	                                            <div className="form-check">
	                                                <input className="form-check-input" type="checkbox" value="" id="rattings" />
	                                                <label className="form-check-label" htmlFor="rattings">
	                                                    Enable star rating on reviews
	                                                </label>
	                                            </div>                                      
	                                        </div>
	                                        <div className="mb-3">
	                                            <label htmlFor="currency" className="form-label"><b>Currency</b></label>
	                                            <select className="form-control" name="" id="currency">
	                                                <option value="usd">USD</option>
	                                                <option value="inr">INR</option>
	                                            </select>
	                                        </div>
	                                        <div className="mb-3">
	                                            <label htmlFor="currency-position" className="form-label"><b>Currency position</b></label>
	                                            <select className="form-control" name="" id="currency-position">
	                                                <option value="left">Left</option>
	                                                <option value="right">RIght</option>
	                                            </select>
	                                        </div>	
		                                    <div className="fixed-bottom-button">
												<button className="btn btn-info btn-md text-white text-uppercase" disabled={ isSubmit  }>{btnText}</button>
											</div>
										</form>
                                    </div>    
                                { /** ECOM Shipping **/}
                                	<div className="tab-pane fade pt-3" id="nav-shipping" role="tabpanel" aria-labelledby="nav-shipping-tab">
							            <form onSubmit = {handleSubmitShipping(ecomShippingSubmit)}>	
							            <h3>shipping</h3>
                               				<div className="fixed-bottom-button">
												<button className="btn btn-info btn-md text-white text-uppercase" disabled={ isSubmit  }>{btnText}</button>
											</div>
										</form>
                                    </div>    
	                            { /** ECOM Tax **/}
	                            	<div className="tab-pane fade pt-3" id="nav-tax" role="tabpanel" aria-labelledby="nav-tax-tab">
							            <form onSubmit = {handleSubmitTax(ecomTaxSubmit)}>	
							            	<h3>Tax</h3>
	                            			<div className="fixed-bottom-button">
												<button className="btn btn-info btn-md text-white text-uppercase" disabled={ isSubmit  }>{btnText}</button>
											</div>
										</form>
                                    </div>    
	                             { /** ECOM Coupon **/}
	                             	<div className="tab-pane fade pt-3" id="nav-coupon" role="tabpanel" aria-labelledby="nav-coupon-tab">
							            <form onSubmit = {handleSubmitCoupon(ecomCouponSubmit)}>	
							            	<h3>Coupon</h3>
	                             			<div className="fixed-bottom-button">
												<button className="btn btn-info btn-md text-white text-uppercase" disabled={ isSubmit  }>{btnText}</button>
											</div>
										</form>
                                    </div>    
									
							</div>
							{ /** EMAIL **/ }
								<div className="tab-pane fade p-3 mb-5 card-border-top" id="nav-email" role="tabpanel" aria-labelledby="nav-email-tab">
									<form onSubmit = {handleSubmitEmail(emailSubmit)}>
										<h5>SMTP Settings</h5>
										<hr />

										<div className="form-group mb-3">
											<label className="form-label">SMTP Host</label>
											<input type="text" 
												className={ errors4.smtp_host ? "form-control input-error " : "form-control" }
												autocomple="off" 
				                                {...emailRegister("smtp_host", { required: true })} 
				                                placeholder= "SMTP Host"
											 />
										</div>
										<div className="form-group mb-3">
											<label className="form-label">SMTP Port</label>
											<input type="text" 
												className={ errors4.smtp_port ? "form-control input-error " : "form-control" }
												autocomple="off" 
				                                {...emailRegister("smtp_port", { required: true })} 
				                                placeholder= "SMTP Port"
											 />
										</div>
										<div className="form-group mb-3">
											<label className="form-label">Email</label>
											<input type="text" 
												className={ errors4.smtp_email ? "form-control input-error " : "form-control" }
												autocomple="off" 
				                                {...emailRegister("smtp_email", { required: true })} 
				                                placeholder= "Email"
											 />
										</div>
										<div className="form-group mb-3">
											<label className="form-label">SMTP Username</label>
											<input type="text" 
												className={ errors4.smtp_username ? "form-control input-error " : "form-control" }
												autocomple="off" 
				                                {...emailRegister("smtp_username", { required: true })} 
				                                placeholder= "SMPT Username"
											 />
										</div>
										<div className="form-group mb-3">
											<label className="form-label">SMTP Password</label>
											<input type="password" 
												className={ errors4.smtp_password ? "form-control input-error " : "form-control" }
												autocomple="off" 
				                                {...emailRegister("smtp_password", { required: true })} 
				                                placeholder= "SMPT Password"
											 />
										</div>
										<div className="fixed-bottom-button">
											<button className="btn btn-info btn-md text-white text-uppercase" disabled={ isSubmit  }>{btnText}</button>
										</div>
									</form>
								</div>

							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	)
}

export default Settings