import React, { useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import axios from 'axios'
import { useForm } from "react-hook-form";
import { useAlert } from 'react-alert'
import CustomFields  from '../../components/CustomFields';
import Title from '../../components/Title'

const EditOrder = () => {
	Title('Edit Order');
	const { register, control,  handleSubmit, reset, formState: { errors } } = useForm();
	const [desc, setDesc]       = useState('');
	const [btnText, setBtnText] = useState('Update');
	const [isSubmit, setIssubmit] = useState(false);
	const alert = useAlert()
	const { id } = useParams(); 
	
	const onSubmit = data => {
		setBtnText('Updating...');
		setIssubmit(true);
		data.content = desc;

		console.log(JSON.stringify(data));
		//console.log(data);
		
		//alert.error('error');
		alert.success('success');
		setIssubmit(false);
		setBtnText('Update');
	}
	
	return (
		<div className="content-wrapper">
			<div className="content-header">
				<div className="container-fluid mb-2">
					<div className="row">
						<div className="col-sm-6">
							<ol className="breadcrumb">
				              <li className="breadcrumb-item"><NavLink to="/admin/">Home</NavLink></li>
				              <li className="breadcrumb-item"><NavLink to="/admin/orders">Orders</NavLink></li>
				              <li className="breadcrumb-item active">Edit Order</li>
				            </ol>
						</div>
						<div className="col-sm-6">
						</div>
					</div>
				</div>
			</div>
			<hr />
			<section className="content order mb-4">
				<div className="container-fluid p-0 mb-3">
					<form onSubmit = {handleSubmit(onSubmit)}>
						<div className="row m-0">
							<div className="col-sm-12 col-md-8 col-lg-9 ">
								<div className="row pb-2 bg-white card-border-top">
									<div className="col-sm-6 col-md-6 col-lg-6">
										<h6 className="pt-2">Billing Address</h6>
										<hr />
										<p><b>Name :</b> Yogesh Sharma</p>
										<p><b>Company :</b> Sharmatech</p>
										<p><b>Address Line 1 :</b> C1 94</p>
										<p><b>Address Line 2 :</b> C Shastripuram</p>
										<p><b>City :</b> Agra</p>
										<p><b>Postalcode / ZIP :</b> 282007</p>
										<p><b>Country / Region :</b> India</p>
										<p><b>State / County :</b> Uttat Pradesh</p>
										<br />
										<p><b>Email :</b> sharmatechinfo@gmail.com</p>
										<p><b>Phone :</b> 9808806967</p>
										<p><b>Customer Notes :</b> First order for testing by admin</p>
									</div>
									<div className="col-sm-6 col-md-4 col-lg-6">
										<h6 className="pt-2">Shipping Address</h6>
										<hr />
										<p><b>Name :</b> Yogesh Sharma</p>
										<p><b>Company :</b> Sharmatech</p>
										<p><b>Address Line 1 :</b> C1 94</p>
										<p><b>Address Line 2 :</b> C Shastripuram</p>
										<p><b>City :</b> Agra</p>
										<p><b>Postalcode / ZIP :</b> 282007</p>
										<p><b>Country / Region :</b> India</p>
										<p><b>State / County :</b> Uttat Pradesh</p>
										<br />
										<p><b>Email :</b> sharmatechinfo@gmail.com</p>
										<p><b>Phone :</b> 9808806967</p>
										<p><b>Customer Notes :</b> First order for testing by admin</p>
									</div>
								</div>
								<div className="row pb-2 bg-white card-border-top mt-3">
									<div className="col-12">
										<h6 className="pt-2">Items</h6>
										<hr />
										<div className="table-responsive">
											<table className="table table-hover">
											  <thead className="thead-light">
											    <tr>
											      <th scope="col" className="w-50">item</th>
											      <th scope="col">Cost</th>
											      <th scope="col">Qty</th>
											      <th scope="col">Total</th>
											    </tr>
											  </thead>
											  <tbody>
											    <tr>
											      <th>Product</th>
											      <td>$100</td>
											      <td>x2</td>
											      <td>$110</td>
											    </tr>
											  </tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="row bg-white justify-content-end">
									<div className="col-md-6 text-end pb-3">
										<p>Items Subtotal:  <b>$10.00</b></p>
										<p>Order Total:  <b>$10.00</b></p>
									</div>
								</div>
								<div className="row order-custom mt-3 mb-2">
									<CustomFields register = {register} control ={control} />
								</div>
							</div>	
							<div className="col-sm-12 col-md-4 col-lg-3 pe-0 mps-0">
								<div className=" p-3 bg-white card-border-top">
									<div className="form-group">
										<label className="m-0">Status</label>
										<hr/>
										<select className="form-select" {...register("status", { required: false })}>
											<option value="ec-pending">Pending Payment</option>
											<option value="ec-processing">Processing</option>
											<option value="ec-on-hold">On hold</option>
											<option value="ec-completed">Completed</option>
											<option value="ec-cancelled">Cancelled</option>
											<option value="ec-refunded">Refunded</option>
											<option value="ec-failed">Failed</option>
										</select>
									</div>
									<div className="form-group mt-3">
										<button className="btn btn-primary" disabled={ isSubmit }>{btnText}</button>
									</div>
								</div>
								
								<div className="p-3 bg-white card-border-top mt-3">
									<div className="form-group">
										<label className="m-0">Payment Method</label>
										<hr/>
										<select className="form-select" {...register("payment", { required: false })}>
											<option value="cash-on-delivery">Cash on delivery</option>
											<option value="paypal">Paypal</option>
											<option value="stripe">Stripe</option>
										</select>
									</div>
								</div>	
								<div className="p-3 bg-white card-border-top mt-3">
									<div className="form-group">
										<label className="m-0">Customer</label>
										<hr/>
										<select className="form-select" {...register("Customer", { required: false })}>
											<option value="1">Admin</option>
											<option value="2">User</option>
											<option value="3">Yogesh</option>
										</select>
									</div>
								</div>
								<div className="p-3 bg-white card-border-top mt-3">
									<div className="form-group">
										<label className="m-0">Order notes</label>
										<hr/>
										<input type="text" className="form-control" {...register("order_notes", { required: false })} />
									</div>
								</div>	
							</div>
						</div>
					</form>
				</div>
			</section>
		</div>
	)
}

export default EditOrder