import React, {useEffect, useState } from 'react'
import { NavLink, useParams , useHistory} from 'react-router-dom'
import axios from 'axios'
import { useForm } from "react-hook-form";
import { useAlert } from 'react-alert'
import MediaBox from '../../components/MediaBox'
import Title from '../../components/Title'
//import { Redirect } from "react-router-dom";

const EditUser = () => {
	Title('Edit User');
	const { id } = useParams();
	
	const { register, handleSubmit, setValue, formState: { errors } } = useForm();
	const [btnText, setBtnText] = useState('Update');
	const [isSubmit, setIssubmit] = useState(false);
	const [image, setImage]   = useState('');
	const [status, setStatus] = useState(true);
	const [userName, setUserName] = useState('user');
	const alert = useAlert()
	const history = useHistory();

	const onSubmit = data => {
		setBtnText('Updating...');
		setIssubmit(true);
		const image_id = document.querySelector('#image_id').value;
		data.image_id  = image_id; 
		axios.post('Users/updateUser/'+id, data)
		.then(response => { 
			let resp = response.data;
			alert.success(resp.msg);
			setIssubmit(false);
			setBtnText('Update');
		})
		.catch(error => {
		  alert.error(error);
		})
	}
//updateUser
	useEffect(() => {
		const fetchPage = async () => {
			await axios.get('Users/getUser/'+id)
			.then(response => { 
				setStatus(response.data.status);
				if(response.data.status){ 
					let data = response.data.data;
					// Set All default values
					setValue('username', data.user_name);
					setValue('useremail', data.user_email);
					setValue('firstname', data.first_name);
					setValue('lastname', data.last_name);
					setValue('userphone', data.user_phone);
					setValue('status', data.status);
					setValue('role', data.user_role);
					setImage(data.image_id);
					setUserName(data.user_name);
				}
			})
			.catch(error => {
			  alert.error(error);
			})
		};
		fetchPage();
	}, []);
// Check post found!
if(!status){
	history.push('/admin/users');
}
	return (
		<div className="content-wrapper">
			<div className="content-header">
				<div className="container-fluid mb-2">
					<div className="row">
						<div className="col-sm-6">
							<ol className="breadcrumb">
				              <li className="breadcrumb-item"><NavLink to="/admin/">Home</NavLink></li>
				              <li className="breadcrumb-item"><NavLink to="/admin/users">Users</NavLink></li>
				              <li className="breadcrumb-item active">{userName}</li>
				            </ol>
						</div>
						<div className="col-sm-6">
						</div>
					</div>
				</div>
			</div>
			<hr />
			<section className="content">
				<div className="container-fluid  p-0 mb-4">
					<form onSubmit = {handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-sm-12 col-md-8 col-lg-8 rounded height-fit">
							<div className='bg-white p-2 mb-3 card-border-top'>
								<div className="form-group p-2">
									<label className="form-label">User Name</label>
									<input type="text" className={ errors.username ? "form-control input-error " : "form-control" }
										autocomple="off" readOnly 
		                                {...register("username", { required: true })} placeholder="User Name" 
									 />
								</div>
								<div className="form-group p-2">
									<label className="form-label">Email</label>
									<input type="email" className={ errors.useremail ? "form-control input-error " : "form-control" }
										autocomple="off" 
		                                {...register("useremail", { required: true })} placeholder="Email" 
									 />
								</div>
								<div className="form-group p-2">
									<label className="form-label">First Name</label>
									<input type="text" className={ errors.firstname ? "form-control input-error " : "form-control" }
										autocomple="off" 
		                                {...register("firstname", { required: true })} placeholder="First Name" 
									 />
								</div>
								<div className="form-group p-2">
									<label className="form-label">Last Name</label>
									<input type="text" className={ errors.lastname ? "form-control input-error " : "form-control" }
										autocomple="off" 
		                                {...register("lastname", { required: true })} placeholder="Last Name" 
									 />
								</div>
								<div className="form-group p-2">
									<label className="form-label">Phone Number</label>
									<input type="text" className={ errors.userphone ? "form-control input-error " : "form-control" }
										autocomple="off" 
		                                {...register("userphone", { required: false })} placeholder="Phone Number" 
									 />
								</div>
								<div className="form-group p-2">
									<label className="form-label">Password</label>
									<input type="text" className={ errors.userpass ? "form-control input-error " : "form-control" }
										autocomple="off" 
		                                {...register("userpass", { required: false })} placeholder="password" 
									/>
									<small>Leave Blank if not want to change</small>
								</div>
								</div>
							</div>	
							<div className="col-sm-12 col-md-4 col-lg-4 pr-0">
								<div className=" p-3 bg-white card-border-top">
									<div className="form-group">
										<label className="m-0">Status</label>
										<hr/>
										<select className="form-select" {...register("status", { required: false })}>
											<option value="1">Active</option>
											<option value="0">Deactive</option>
										</select>
									</div>
									<div className="form-group mt-3">
										<button className="btn btn-primary" disabled={ isSubmit  }>{btnText}</button>
									</div>
								</div>
								<div className=" p-3 bg-white card-border-top mt-3">
									<div className="form-group">
										<label className="m-0">Roles</label>
										<hr/>
										<select className="form-select" {...register("role", { required: false })}>
											<option value="admin">Administrator</option>
											<option value="subscriber">Subscriber</option>
											<option value="Author">Author</option>
										</select>
									</div>
								</div>	
								<div className=" p-3 bg-white card-border-top mt-3">
									<div className="form-group">
										<label className="m-0">User Image</label>
										<hr/>
											<MediaBox image_id={image} />
									</div>
								</div>	
							</div>
						</div>
					</form>
				</div>
			</section>

		</div>
	)
}

export default EditUser