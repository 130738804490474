//import { useState, useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import AdminRoutes from '../routes/AdminRoutes'
import Sidebar from '../components/admin/Sidebar'
import Header from '../components/admin/Header'

import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
//topbar loader
import topbar from "topbar"

function Admin(props) {
	// Check is admin login or not 
	const isLoggedin = sessionStorage.getItem('isAdminLogin');
	if (!isLoggedin) {
		return (
			<Redirect to="/admin/login" />
		)
	}
	//Topbar loader
	topbar.show();
	setTimeout(
		() => topbar.hide(),
		2000
	);

	topbar.config({
		autoRun: true,
		barThickness: 3,
		barColors: {
			'0': '#f4832a',
		},
		shadowBlur: 10,
		shadowColor: '#f4832a'
	})
	
	const options = {
		position: positions.TOP_RIGHT,
		timeout: 3000,
		offset: '0px',
		transition: transitions.SCALE,
		containerStyle: {
			top: '2px',
			zIndex: 9999,
		}
	}

	return (
		<>
			<div className="wrap">
				<AlertProvider template={AlertTemplate} {...options}>

					<Header />
					<Sidebar />
					<div className="main-wrap">
						{
							AdminRoutes.map((prop, key) => {
								let path = prop.layout + prop.path;
								if (prop.isSingle) {
									path = prop.layout + prop.path + ':id';
								}
								return (
									<Route
										exact
										path={path}
										component={prop.view}
										key={key}
									/>
								);

							})
						}
					</div>
				</AlertProvider>

			</div>
		</>
	);
}

export default Admin