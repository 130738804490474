import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useForm } from "react-hook-form";
import { useAlert } from 'react-alert'
//import axios from 'axios'
import PostTags from '../../components/PostTags'
import Title from '../../components/Title'

const Tags = () => {
	Title('Tags');
	const { register, handleSubmit, reset, formState: { errors } } = useForm();
	const [btnText, setBtnText] = useState('Publish');
	const [isSubmit, setIssubmit] = useState(false);
	const alert = useAlert()

	const onSubmit = data => {
		setBtnText('Publishing...');
		setIssubmit(true);

		console.log(JSON.stringify(data));

		reset();
		//alert.error('error');
		alert.success('success');
		setIssubmit(false);
		setBtnText('Publish');
	}

	return (
		<div className="content-wrapper">
			<div className="content-header">
				<div className="container-fluid mb-2">
					<div className="row">
						<div className="col-sm-6">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><NavLink to="/admin/">Home</NavLink></li>
								<li className="breadcrumb-item"><NavLink to="/admin/blogs">Blogs</NavLink></li>
								<li className="breadcrumb-item active">Tags</li>
							</ol>
						</div>
						<div className="col-sm-6">
						</div>
					</div>
				</div>
			</div>
			<hr />
			<section className="content">
				<div className="container-fluid p-0 mb-4">
					<div className="row">
						<div className="col-sm-12 col-md-4 col-lg-4 rounded height-fit">
							<div className='mb-3 p-3 bg-white card-border-top'>
								<h6>Add New</h6>
								<hr />
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="form-group mb-3">
										<label className="form-label">Name</label>
										<input type="text"
											className={errors.name ? "form-control input-error " : "form-control"}
											autocomple="off"
											{...register("name", { required: true })}
										/>
									</div>
									<div className="form-group mb-3">
										<label className="form-label">Description</label>
										<textarea
											className="form-control"
											autocomple="off"
											{...register("desc", { required: false })}
										></textarea>
									</div>
									<div className="form-group">
										<button className="btn btn-primary" disabled={isSubmit}>{btnText}</button>
									</div>

								</form>
							</div>
						</div>
						<div className="col-sm-12 col-md-8 col-lg-8">
							<PostTags />
						</div>
					</div>

				</div>
			</section>
		</div>
	)
}

export default Tags