import React from 'react'
import { NavLink } from 'react-router-dom' //, useParams 
import Charts, { PieChart } from '../../components/admin/Charts';
import Title from '../../components/Title'

const Dashboard = (props) => {
	Title('Dashboard');
	//const { id } = useParams(); 
	return (
		<div className="content-wrapper">
			<div className="content-header ">
				<div className="container-fluid mb-2">
					<div className="row">
						<div className="col-sm-6">
							<ol className="breadcrumb">
								{/* <li className="breadcrumb-item"><NavLink to="/admin/">Home</NavLink></li>
								<li className="breadcrumb-item active">Dashboard</li> */}
							</ol>
						</div>
						<div className="col-sm-6">
						</div>
					</div>
				</div>
				{/* <hr /> */}
			</div>
			<section className="content">
				<div className="container-fluid p-0">
					<div className="row">
						<div className="col-md-6 mb-2">
							<div className="card card-border-top border-0 radius-0">
								<div className="card-header bg-white ">
									<h6>Users</h6>
								</div>
								<div className="card-body">
									<Charts />
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="card card-border-top border-0 radius-0">
								<div className="card-header bg-white">
									<h6>Users</h6>
								</div>
								<div className="card-body">
									<PieChart />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	)
}

export default Dashboard