import React, { useState} from 'react'
import { NavLink, useHistory} from 'react-router-dom'
import { useForm } from "react-hook-form";
import axios from 'axios'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useAlert } from 'react-alert'
import MediaBox from '../../components/MediaBox'
import { GetPostCategories } from '../../components/PostCategories';
import { GetPostTags } from '../../components/PostTags';
import CustomFields  from '../../components/CustomFields';
import Title from '../../components/Title'

const AddBlog = () => {
	Title('Add Post');
	const { register,control, handleSubmit, reset, formState: { errors } } = useForm();
	const [desc, setDesc]  = useState('');
	const [btnText, setBtnText] = useState('Publish');
	const [isSubmit, setIssubmit] = useState(false);
	const alert = useAlert()
	const history = useHistory();

	const onSubmit = data => {
		setBtnText('Publishing...');
		setIssubmit(true);
		data.content = desc;
		const image_id = document.querySelector('#image_id').value;
		data.image_id  = image_id;
		axios.post('Posts/addPost', data)
		.then(response => { 
			let resp = response.data;
			alert.success(resp.msg);
			setIssubmit(false);
			setBtnText('Publish');
			history.push('/admin/blog/'+resp.id);
		})
		.catch(error => {
		  alert.error(error);
		});
	}

	return (
		<div className="content-wrapper">
			<div className="content-header">
				<div className="container-fluid mb-2">
					<div className="row">
						<div className="col-sm-6">
							<ol className="breadcrumb">
				              <li className="breadcrumb-item"><NavLink to="/admin/">Home</NavLink></li>
				              <li className="breadcrumb-item"><NavLink to="/admin/blogs">Blogs</NavLink></li>
				              <li className="breadcrumb-item active">Add Blog</li>
				            </ol>
						</div>
						<div className="col-sm-6">
						</div>
					</div>
				</div>
			</div>
			<hr />
			<section className="content">
				<div className="container-fluid p-0 mb-4">
					<form onSubmit = {handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-sm-12 col-md-8 col-lg-8 rounded height-fit ">
								<div className="form-group mb-3 bg-white p-3 card-border-top">
									<label className="form-label">Title</label>
									<input type="text" 
										className={ errors.title ? "form-control input-error " : "form-control" }
										autocomple="off" 
		                                {...register("title", { required: true })} 
									 />
								</div>
								<div className="form-group mb-3 bg-white p-3 card-border-top">
									<label className="form-label">Description</label>
									<CKEditor
					                    editor={ ClassicEditor }
					                    data= {desc}
					                    onChange={ ( event, editor ) => {
					                        const data = editor.getData();
					                        setDesc(data);
					                    } }
					                />
									<label className="form-label mt-3">Short Description </label>
									<textarea className="form-control" autocomple="off" 
		                                {...register("excerpt", { required: false })} 
									 ></textarea>
								</div>
								<CustomFields register = {register} control ={control} />
							</div>	
							<div className="col-sm-12 col-md-4 col-lg-4 ">
								<div className="p-3 bg-white card-border-top">
									<div className="form-group mb-3">
										<label className="m-0">Status</label>
										<hr />
										<select className="form-select" {...register("status", { required: false })}>
											<option value="publish">Publish</option>
											<option value="pending">Pending</option>
										</select>
									</div>
									<div className="form-group">
										<button className="btn btn-primary" disabled={ isSubmit  }>{btnText}</button>
									</div>
								</div>
								<div className="p-3 bg-white card-border-top mt-3">
									<label className="m-0">Category</label>
									<hr/>
									<div className="catetag">
										<GetPostCategories register = {register} />
									</div>
								</div>
								<div className="p-3 bg-white card-border-top mt-3">
									<label className="m-0">Tags</label>
									<hr/>
									<div className="catetag">
										<GetPostTags register = {register} />
									</div>
								</div>
								<div className="p-3 bg-white card-border-top mt-3">
									<div className="form-group">
										<label className="m-0">Featured Image</label>
										<hr />
										<MediaBox />
									</div>
								</div>	
							</div>
						</div>
					</form>
				</div>
			</section>

		</div>
	)
}

export default AddBlog