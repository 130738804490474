import {  useFieldArray } from "react-hook-form";

const CustomFields = (props) => { 
const control =  props.control;
  const { fields, append, remove} = useFieldArray({
    control,
    name: "customField"
  });
	return (
		<div className="custom-fields border  bg-white">
			<p className="p-2 mb-0 bg-theme text-white"><b>Custom Field</b></p>
			<hr className="mt-0 mb-2" />
			<div className="meta px-2">
				<div className="row m-0 bg-gray align-items-center h-100 mb-3 d-none">
					<div className="col-6 text-center py-2">
						<p className="m-auto">Name</p>
					</div>
					<div className="col-6 text-center py-2">
						<p className="m-auto">Value</p>
					</div>
				</div>
			 	<ul className="list-unstyled ">
			        {fields.map((item, index) => (
			          <li key={item.id} className="d-inline-flex gap-2 mb-2">
			            <input
			              className="form-control  mr-2"
			              placeholder= "Name"
			              {...props.register(`customField.${index}.metaKey`)}
			            />
			            <input
			              className="form-control mr-2"
			              placeholder= "Value"
			              {...props.register(`customField.${index}.metaVal`)}
			            />
			          
			            <button type="button" className="btn btn-danger " onClick={() => remove(index)}>Delete</button>
			          </li>
			        ))}
			    </ul>
		      	<button type="button" className="btn btn-primary btn-sm mb-2" onClick={() => append({ metaKey: "", metaVal: "" })} >Add new</button>
			</div>
		</div>
	)
}

export default CustomFields