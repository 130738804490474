import React from 'react'
import { NavLink, Link } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import AdminRoutes from '../../routes/AdminRoutes'

const Sidebar = () => {
	return (
		<>
			<div className={ isMobile ? 'main-sidebar' : 'main-sidebar open'}>
				<Link to="/admin" className="brand-link">
					{sessionStorage.getItem('site_title')}
				</Link>
				<div className="nav mt-2">
					<ul className="nav nav-pills nav-sidebar flex-column">
						{
							AdminRoutes.map( (data, key) => {
							if(data.display)
								return (
									<li className="nav-item" key= {key} >
										<NavLink exact to={data.layout + data.path}><i className={"me-2 " +  data.icon } ></i>{data.title}</NavLink>
									</li>
								);
							 return '';		
							})
						}
						
					</ul>
				</div>
			</div>
		</>
	)
}
export default Sidebar