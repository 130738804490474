import { initializeApp } from "firebase/app";
import { getMessaging , getToken, onMessage} from  "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDShY_kh9fSYCyJ5XyZIwGomgVf1vHHi5A",
  authDomain: "my-react-40d9e.firebaseapp.com",
  projectId: "my-react-40d9e",
  storageBucket: "my-react-40d9e.appspot.com",
  messagingSenderId: "685892482006",
  appId: "1:685892482006:web:d10cd738a17cedd60eea77",
  measurementId: "G-5JN764K6M0"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging();
const publicKey = 'BLfZ0eL3E90M_gxo9GgCCapepQjOayKzGdqPzVCB3A3OGYaD-xzUXwBVP0xNFPXj1QUMzznmW6gfkyewwEODPo0';

const Token = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await getToken(messaging, {  vapidKey: publicKey  })
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
  return currentToken;
};

// Move this on particular file 
// onMessage(messaging, (payload) =>{ 
//   console.log('Message received firebase. ', payload)
//   let count = localStorage.getItem('noti-count');
//   let Tl = payload['data']['title'];
//   let body = payload['data']['body'];
//   if(count){
//     localStorage.setItem('noti-count', parseInt(count) + 1 );
//   }else{
//     localStorage.setItem('noti-count', 1);
//   }
// });

export {Token }