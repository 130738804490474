import React, { useState, useEffect} from 'react'
import { NavLink, useParams } from 'react-router-dom'
import axios from 'axios'
import Loader from "react-loader-spinner"
import Datatable from 'react-data-table-component'
import Title from '../../components/Title'

const Products = () => {
	Title('Products');
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(false);

	const fetchProducts = async () => {
		setLoading(true);
		const res = await axios.get('https://jsonplaceholder.typicode.com/posts');
		setProducts(res.data);
		setLoading(false);
	};
	const DeleteProduct = (id)=>{
		alert(id);
	}
	useEffect(() => {
		fetchProducts();
	}, []);

	const columns = [
		{
			name: 'Name',
			selector: row => row.userId,
			sortable: true,
			grow: 1

		},
		{
			name: 'Stock',
			selector: row => row.userId,
			sortable: true,
			grow: 0

		},
		{
			name: 'Price',
			selector: row => row.userId,
			sortable: true,
			grow: 0

		},
		{
			name: 'Author',
			selector: row => row.title,
			sortable: true,
			grow: 1
		},
		{
			name: 'Date',
			selector: row => row.date,
			sortable: true,
			wrap: true,
			grow: 1
		},
		{
			name: 'Actions',
		    wrap: false,
		    right: false,
		    cell: row => <div>
		    			<button className="btn btn-danger btn-sm me-2" onClick = { () => DeleteProduct(row.userId) } >Delete</button>
		    			<NavLink to={'/admin/product/'+row.userId} className="btn btn-info btn-sm me-2">Edit</NavLink>
		    			<NavLink to={'/admin/edit-category/'+row.userId} className="btn btn-primary btn-sm">View</NavLink>
		    			</div>,
		 	right: true,

		},
	];
	
	return (
		<div className="content-wrapper">
			<div className="content-header">
				<div className="container-fluid mb-2 px-0">
					<div className="row">
						<div className="col-sm-6">
							<ol className="breadcrumb">
				              <li className="breadcrumb-item"><NavLink to="/admin/">Home</NavLink></li>
				              <li className="breadcrumb-item active">Products</li>
				            </ol>
						</div>
						<div className="col-sm-6 text-sm-end">
							<NavLink to="/admin/add-product" className="btn btn-sm btn-primary">Add Product</NavLink>
							<NavLink to="/admin/product-categories" className="btn btn-sm btn-primary ms-2">Categories</NavLink>
							<NavLink to="/admin/product-tags" className="btn btn-sm btn-primary ms-2">Tags</NavLink>
						</div>
					</div>
				</div>
			</div>
			<hr />
			{ loading &&
				<div className="content-wrapper text-center">
					<Loader
						type="ThreeDots"
						color="#00BFFF"
						height={100}
						width={100}
					/>
				</div>
			}
			<section className="content">
				<div className="container-fluid px-0">
					<div className="row">
						<Datatable
							title="Products"
							columns={columns}
							data={products}
							pagination
							highlightOnHover
							noHeader
							className="table"
						/>
					</div>
				</div>
			</section>
		</div>
	)
}

export default Products