import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner"
import { useAlert } from 'react-alert'
import Title from '../../components/Title'

const Media = () => {
	Title('Media');
	const [imageDetails, setImageDetails] = useState(false);
	let [responseData, setResponseData] = useState([]);
	let [isNext, isNextFunc] = useState(false);
	let [pageCount, setCount] = useState(1);
	let [loading, setLoading] = useState(true);
	const alert = useAlert()

	const showImage = (data) => {
		setImageDetails(data);
	}
	const closeModel = () => {
		setImageDetails(false);
	}

	const deleteImage = (id) => {
		//console.log(id);
		setLoading(true);
		fetchData();
		alert.success('deleted');
	}
	const fetchData = () => { 
		setTimeout(() => {
			axios.get(`https://jsonplaceholder.typicode.com/photos?_page=${pageCount}&_limit=16`)
			.then((response) => {
				setResponseData([...responseData, ...response.data]);
				isNextFunc(true);
				setCount(pageCount + 1);
				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
		}, 1500);	

	}
	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className="content-wrapper">
			<div className="content-header">
				<div className="container-fluid mb-2">
					<div className="row">
						<div className="col-sm-6">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><NavLink to="/admin/">Home</NavLink></li>
								<li className="breadcrumb-item active">Media</li>
							</ol>
						</div>
						<div className="col-sm-6">

						</div>
					</div>
				</div>
			</div>
			<hr />
			<section className="content">
				<div className="container-fluid">
					<div className="row mb-2">
						<div className="col-md-12 bg-white pb-4 add-media border-dash">
							<div className="new_media">
								<form className="text-center mt-3">
									<button className="btn btn-info text-white mt-2 shadow-none" id="new_media"><i className="fa fa-cloud-upload"></i> Upload </button>
									<h5 className="mediatext">Allowed Only JPG, JPEG, PNG, GIF, PDF, DOCX, TXT</h5>
								</form>
							</div>
						</div>
					</div>
					{ loading &&
						<div className="content-wrapper text-center">
							<Loader
								type="ThreeDots"
								color="#00BFFF"
								height={100}
								width={100}
							/>
						</div>
					}
					<InfiniteScroll
						dataLength={responseData.length}
						next={fetchData}
						hasMore={isNext}
						className="row"
						loader={
							<div className="text-center">
								<Loader
									type="ThreeDots"
									color="#00BFFF"
									height={50}
								/>
							</div>
						}
					>
						<div className="bg-white p-3 card-border-top  imagebox mt-3">
							<div className="row">
								{
									responseData.map((data, index) => (
										<div key={index} className="col-sm-12 col-md-4 col-lg-3 mb-2 thumb-preview ">
											<div className="show">
												<a className="imgdetail shadow-none" onClick={() => showImage(data)} ><i className="fa fa-ellipsis-v" aria-hidden="true"></i></a>
											</div>
											<div className="delete">
												<a className="deletefile shadow-none" onClick={() => deleteImage(data.id)} ><i className="fa fa-trash" ></i></a>
											</div>
											<img alt="img" key={data.albumId} className="rounded img-thumbnails" src={data.thumbnailUrl} />
											<div className="title">
												<p>{data.title}</p>
											</div>
										</div>
									))
								}
							</div>
						</div>
					</InfiniteScroll>
				</div>
			</section>
			{imageDetails &&
				<div className="modal fade show" style={{ display: 'block' }} >
					<div className="modal-dialog modal-lg modal-dialog-right" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">{imageDetails.title} </h5>
								<button type="button" className="btn-close" onClick={closeModel} data-dismiss="modal" aria-label="Close">
								</button>
							</div>
							<div className="modal-body">
								<div className="mb-3">
									<label className="form-label">File name</label>
									<input type="text" value={imageDetails.title} className="form-control" id="filename" readOnly />
								</div>
								<div className="mb-3">
									<label className="form-label">File url</label>
									<input type="text" value={imageDetails.url} className="form-control" id="fileurl" readOnly />
								</div>
								<div className="mb-3">
									<label  className="form-label">File type</label>
									<input type="text" value="image" className="form-control" id="filename" readOnly />
								</div>
								<div className="mb-3">
									<label  className="form-label">Upload by</label>
									<input type="text" value="admin" className="form-control" id="filename" readOnly />
								</div>
								<div className="mb-3 text-center">
									<a href={setImageDetails.url} download className="btn btn-primary text-white">Download</a>
								</div>
							</div>
						</div>
					</div>
				</div>

			}
		</div>

	)
}

export default Media