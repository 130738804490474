import React, {useRef, useEffect} from "react";
import axios from 'axios'

const Title = (title, prevailOnUnmount = false) => {
    const defaultTitle = useRef(document.title);

    useEffect(() => {
        axios.get('Admin/getTitle')
        .then(response => {
            document.title = title + ' - ' + response.data.title;
        });
    }, [title]);

    useEffect(() => () => {
        if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
        }
    }, [])
}
export default Title;