import { Bar, Pie } from 'react-chartjs-2'
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels);

let data = [12, 19, 3, 5, 2, 3, 10, 12, 5, 2, 2, 21];
let labels = ['Jan', 'Feb', 'March', 'Aprl', 'May', 'Jun', 'July', 'Aug', 'Sem', 'Oct', 'Nov', 'Dec'];

let backgroundColor = [
				                'rgba(255, 99, 132, 0.2)',
				                'rgba(54, 162, 235, 0.2)',
				                'rgba(255, 206, 86, 0.2)',
				                'rgba(75, 192, 192, 0.2)',
				                'rgba(153, 102, 255, 0.2)',
				                'rgba(255, 159, 64, 0.2)'
				            ];
let borderColor = 	[
				                'rgba(255, 99, 132, 1)',
				                'rgba(54, 162, 235, 1)',
				                'rgba(255, 206, 86, 1)',
				                'rgba(75, 192, 192, 1)',
				                'rgba(153, 102, 255, 1)',
				                'rgba(255, 159, 64, 1)'
				            ];					            
const Charts = () => {
	return (
		<div>
			<Bar
				data={{
					labels : labels,
					datasets :[
						{
							label : '# of users', 
							data : data,
							backgroundColor: backgroundColor, 
				            borderColor:borderColor ,
				            borderWidth: 1,

					    }, 
					    
					]
				}}
				//width={600}
				height  = {400}
				options = {{ 
									maintainAspectRatio: false,
									plugins: {
				            title: {
				                display: true,
				                text: 'Users'
				            }, 
				            legend: {
					            display: false,
					            position: 'right',
					       		},
									}			     	
			         }}	
			/>
		</div>
	)
}
export const PieChart = () => {
	return (
		<div>
			<Pie
				data={{
						labels : labels,
						datasets :[
							{
								label : '# of users', 
								data : data,
								backgroundColor: backgroundColor, 
					            borderColor:borderColor ,
					            borderWidth: 1
						    }
						]
				}}
				//width={600}
				height={300}
				options={{ 
								maintainAspectRatio: false, 
								plugins: {
			            		title: {
				                display: true,
				                text: 'Users'
				          		}, 
						          legend: {
							            	display: true,
							            	position: 'right',
							        },
				     		}
				}}	
			/>
		</div>
	)
}
export default Charts