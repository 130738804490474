import React, {useEffect, useState} from 'react'
import { NavLink, useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import { useForm } from "react-hook-form";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useAlert } from 'react-alert'
import MediaBox from '../../components/MediaBox'
//import { Redirect } from "react-router-dom";
import Title from '../../components/Title'

const EditPage = () => {
	Title('Edit Page');
	const { register, handleSubmit, setValue, formState: { errors } } = useForm();
	const [desc, setDesc]       = useState('');
	const [btnText, setBtnText] = useState('Update');
	const [isSubmit, setIssubmit] = useState(false);
	const [image, setImage]   = useState('');
	const [status, setStatus] = useState(true);
	const alert = useAlert()
	const { id } = useParams(); 
	const history = useHistory();
	
	const onSubmit = data => {
		setBtnText('Updating...');
		setIssubmit(true);
		const image_id = document.querySelector('#image_id').value;
		data.image_id  = image_id;
		data.content = desc;
		axios.post('Posts/updatePage/'+id, data)
		.then(response => { 
			let resp = response.data;
			alert.success(resp.msg);
			setIssubmit(false);
			setBtnText('Update');
		})
		.catch(error => {
		  alert.error(error);
		})
	}
	useEffect(() => { 
		const fetchPage = async () => {
			await axios.get('Posts/getPage/'+id)
			.then(response => { 
				setStatus(response.data.status);
				
				if(response.data.status){ 
					let data = response.data.data;
					// Set All default values
					setDesc(data.content);
					setValue('title', data.title);
					setValue('status', data.status);
					setImage(data.image_id);
				}
			})
			.catch(error => {
			  alert.error(error);
			})
		};
		fetchPage();
	}, []);
	// Check post found!
	if(!status){
		history.push('/admin/pages');
	}
	return ( 
		<div className="content-wrapper">
			<div className="content-header">
				<div className="container-fluid mb-2">
					<div className="row">
						<div className="col-sm-6">
							<ol className="breadcrumb">
				              <li className="breadcrumb-item"><NavLink to="/admin/">Home</NavLink></li>
				              <li className="breadcrumb-item"><NavLink to="/admin/pages">Pages</NavLink></li>
				              <li className="breadcrumb-item active">Edit Page</li>
				            </ol>
						</div>
						<div className="col-sm-6">
						</div>
					</div>
				</div>
			</div>
			<hr />
			<section className="content">
				<div className="container-fluid p-0 mb-4">
					<form onSubmit = {handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-sm-12 col-md-8 col-lg-8 rounded">
								<div className="form-group p-3 bg-white card-border-top mb-3">
									<label className="form-label">Title</label>
									<input type="text" className={ errors.title ? "form-control input-error " : "form-control" }
										autocomple="off" 
		                                {...register("title", { required: true })} 
									 />
								</div>
								<div className="form-group  p-3 bg-white mb-3 card-border-top">
									<label className="form-label">Description</label>
									<CKEditor
					                    editor={ ClassicEditor }
					                    data= {desc}
					                    onChange={ ( event, editor ) => {
					                        const data = editor.getData();
					                        setDesc(data);
					                    } }
					                   
					                />
								</div>
							</div>	
							<div className="col-sm-12 col-md-4 col-lg-4 pr-0">
								<div className=" p-3 bg-white card-border-top">
									<div className="form-group">
										<label className="m-0">Status</label>
										<hr/>
										<select className="form-select" {...register("status", { required: false })}>
											<option value="publish">Publish</option>
											<option value="pending">Pending</option>
										</select>
									</div>
									<div className="form-group mt-3">
										<button className="btn btn-primary" disabled={ isSubmit }>{btnText}</button>
									</div>
								</div>
								<div className=" p-3 bg-white card-border-top mt-3">
									<div className="form-group">
										<label className="m-0">Feature Image</label>
										<hr/>
											<MediaBox image_id={image} />
									</div>
								</div>	
							</div>
						</div>
					</form>
				</div>
			</section>
		</div>
	)
}

export default EditPage