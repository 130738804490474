import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import Loader from "react-loader-spinner"
import Datatable from 'react-data-table-component'
import Title from '../../components/Title'

const Users = () => {
	Title('Users');
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);

	const fetchUsers = async () => {
		setLoading(true);
		await axios.get('Users/getAllUsers')
	    .then(response => { 
			if(response.data.status)
			setUsers(response.data.data);
	    })
	    .catch(error => {
		  alert.error(error);
	    })
		setLoading(false);
	};
	useEffect(() => {
		fetchUsers();
	}, []);

	const DeleteUser = (id)=>{
		alert(id);
	}
	
	const columns = [
		{
			name: 'Username',
			selector: row => row.user_name,
			sortable: true,
			grow: 1

		},
		// {
		// 	name: 'Name',
		// 	selector: row => row.user_name,
		// 	sortable: true,
		// 	grow: 1
		// },
		{
			name: 'Email',
			selector: row => row.user_email,
			sortable: true,
			wrap: true
			//right: true,
		},
		{
			name: 'Role',
			selector: row => row.user_role,
			sortable: true,
			wrap: true
			//right: true,
		},
		{
			name: 'Actions',
		    wrap: false,
		    right: false,
		    cell: row => <div>
						{  row.id > 1 &&
		    				<button className="btn btn-danger btn-sm me-2" onClick = { () => DeleteUser(row.id) } >Delete</button>
						}
						<NavLink to={'/admin/user/'+row.id} className="btn btn-info btn-sm me-2">Edit</NavLink>
		    			<NavLink to={'/user/'+row.user_nicename} className="btn btn-primary btn-sm">View</NavLink>
		    			</div>,
		 	right: true,
		},
	];
	
	return (
		<div className="content-wrapper">
			<div className="content-header">
				<div className="container-fluid mb-2">
					<div className="row">
						<div className="col-sm-6">
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><NavLink to="/admin/">Home</NavLink></li>
								<li className="breadcrumb-item active">Users</li>
							</ol>
						</div>
						<div className="col-sm-6 text-sm-end">
							<NavLink to="/admin/add-user" className="btn btn-sm btn-primary">Add User</NavLink>
						</div>
					</div>
				</div>
			</div>
			<hr />
			{ loading &&
				<div className="content-wrapper text-center">
					<Loader
						type="ThreeDots"
						color="#00BFFF"
						height={100}
						width={100}
					/>
				</div>
			}
			<section className="content">
				<div className="container-fluid px-0">
					<div className="row">
						<Datatable
							title="Posts"
							columns={columns}
							data={users}
							pagination
							highlightOnHover
							noHeader
							className="table"
						/>
					</div>
				</div>
			</section>
		</div>
	)
}

export default Users